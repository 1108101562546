<template>
  <v-card class="pa-0 mb-5">
    <v-card-title class="red">
      <h3 v-if="notification.by_system" class="mr-3">
        Ésta notificación es obligatoria y gratis para que los votantes puedan
        votar
      </h3>
      <v-btn outlined dark @click="notificationPreview">Vista previa</v-btn>
      <v-btn
        absolute
        right
        text
        dark
        @click.prevent="removeNotification(notification)"
        :disabled="disable"
      >
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-5">
      <div class="grey lighten-3 mb-5">
        <p>
          <strong>
            Lista de variables disponibles para personalizar la notificación
          </strong>
        </p>
        <ul>
          <li v-for="variable in variables" :key="variable.id">
            <span @click.prevent="appendVariable(variable.name)">
              {{ variable.name }} </span
            >: {{ variable.meaning }}
          </li>
        </ul>
      </div>
      <v-text-field
        label="Ingrese el titulo de la notificación"
        color="red"
        v-model="notification.subject"
        :error-messages="errorFor('subject')"
        @blur="submit"
        :disabled="disable"
      ></v-text-field>
      <wswgComponent
        :parentData="notification.body"
        @wswgSubmit="wswgSubmit"
        :disabled="disable"
      />
      <v-row>
        <v-col>
          <v-dialog
            ref="dialogDate"
            v-model="modalDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueDateFormatted"
                label="Fecha"
                readonly
                v-bind="attrs"
                :error-messages="errorFor('notify_at')"
                v-on="on"
                :disabled="disabled"
              ></v-text-field>
            </template>
            <v-date-picker v-model="valueDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalDate = false"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="formatDateTimes">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <v-dialog
            ref="dialogTime"
            v-model="modalTime"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueTime"
                label="Hora"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
              ></v-text-field>
            </template>
            <v-time-picker v-model="valueTime" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalTime = false"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="formatDateTimes">OK</v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="dialog">
      <v-card class="pa-5">
        <v-card-title>
          <div v-html="preview.subject"></div>
        </v-card-title>
        <v-divider class="py-3"></v-divider>
        <v-card-text>
          <div v-html="preview.body"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import errors from "@/mixins/errors";
import templateVariables from "@/services/template_variables";
import notifications from "@/services/notifications";
import wswgComponent from "@/components/wswg/wswg.vue";

export default {
  name: "NotificationForm",
  mixins: [errors],
  props: ["notification", "election", "disabled"],
  components: {
    wswgComponent
  },
  data: () => ({
    dialog: false,
    dialogDate: false,
    dialogTime: false,
    modalDate: false,
    modalTime: false,
    valueDate: null,
    valueTime: null,
    variables: [],
    preview: {
      subject: "",
      body: ""
    }
  }),
  computed: {
    valueDateFormatted() {
      return this.formatDate(this.valueDate);
    },
    disable() {
      if (this.notification.by_system) {
        return true;
      } else {
        return this.disabled;
      }
    }
  },
  mounted() {
    const [start_date, start_time] = this.notification.notify_at.split("T");
    const [start_time_hour, start_time_minutes] = start_time.split(":");

    this.valueDate = start_date;
    this.valueTime = start_time_hour + ":" + start_time_minutes;
    templateVariables.index().then(response => {
      this.variables = response.data;
    });
  },
  methods: {
    removeNotification(notification) {
      let self = this;
      notifications.destroy(notification).then(() => {
        // destroy the vue listeners, etc
        self.$destroy();

        // remove the element from the DOM
        self.$el.parentNode.removeChild(self.$el);
      });
    },
    appendVariable(variableName) {
      // TODO: insert variable to textarea cursor position
      console.log(variableName);
    },
    submit() {
      let self = this;
      notifications
        .update(this.notification)
        .then(() => {
          self.errors = {};
        })
        .catch(error => {
          if (error.response) {
            self.errors = error.response.data;
          }
        });
    },
    wswgSubmit(data) {
      this.notification.body = data;
      this.submit();
    },
    formatDateTimes() {
      this.notification.notify_at = this.valueDate + " " + this.valueTime;
      this.modalDate = false;
      this.modalTime = false;
      this.submit();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    notificationPreview() {
      let parseSubject = this.notification.subject;
      parseSubject = parseSubject.replace(
        "{{{organization_name}}}",
        this.election.organization.name
      );
      parseSubject = parseSubject.replace(
        "{{{election_name}}}",
        this.election.name
      );

      let parseBody = this.notification.body;
      parseBody = parseBody.replace("{{{voter_name}}}", "Frank Florentino");
      parseBody = parseBody.replace("{{{election_name}}}", this.election.name);
      parseBody = parseBody.replace(
        "{{{organization_name}}}",
        this.election.organization.name
      );
      parseBody = parseBody.replace(
        "{{{confirmation_url}}}",
        '<a href="#">Confirmar</a>'
      );
      parseBody = parseBody.replace("{{{start_at}}}", this.election.start_at);
      parseBody = parseBody.replace("{{{end_at}}}", this.election.end_at);
      parseBody = parseBody.replace("{{{url}}}", '<a href="#">Votar</a>');

      this.preview.subject = parseSubject;
      this.preview.body = parseBody;
      this.dialog = true;
    }
  }
};
</script>