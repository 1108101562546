<template>
  <v-card flat>
    <v-card-text>
      <notificationForm
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        :election="election"
        :disabled="!canEdit"
      ></notificationForm>
      <v-btn
        class="no-underline mt-5"
        color="yellow darken-3"
        @click="addNotification"
        block
        :disabled="!canEdit"
      >
        <v-icon left>mdi-plus-circle</v-icon>Agregar notificación
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('loaddata')"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>Atrás
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('results')"
      >
        Siguiente<v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import notifications from "@/services/notifications";
import notificationForm from "@/components/notifications/Form";
import electionCanEdit from "@/mixins/electionCanEdit";

export default {
  name: "BallotNotification",
  mixins: [electionCanEdit],
  props: ["election"],
  data: () => ({
    notifications: []
  }),
  components: {
    notificationForm
  },
  watch: {
    election() {
      this.loadNotifications();
    }
  },
  methods: {
    // getRoute(option) {
    //   return "/wizard/" + this.electionId + "/" + option;
    // },
    loadNotifications() {
      notifications.index(this.election.id).then(response => {
        this.notifications = response.data;
      });
    },
    addNotification() {
      let self = this;
      notifications.create(this.election.id).then(response => {
        self.notifications.push(response.data);
      });
    },
    getRoute(option) {
      return "/wizard/" + this.election.id + "/" + option;
    }
  }
};
</script>