import axios from "axios";
import authHeader from "./authHeader";

const API = process.env.VUE_APP_API_URL;

export default {
  index(electionId) {
    return axios.get(process.env.VUE_APP_API_URL + "/elections/" + electionId + '/notifications', {
      headers: authHeader()
    });
  },
  create(electionId) {
    return axios.post(process.env.VUE_APP_API_URL + "/elections/" + electionId + '/notifications', {}, {
      headers: authHeader()
    });
  },
  update(notification) {
    return axios.put(API + "/elections/" + notification.election_id + '/notifications/' + notification.id, { "notification": notification }, {
      headers: authHeader()
    });
  },
  destroy(notification) {
    return axios.delete(API + "/elections/" + notification.election_id + '/notifications/' + notification.id, {
      headers: authHeader()
    });
  }
};